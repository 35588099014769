import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import './Glitter.css';


function Glitter() {
  const { id } = useParams();
  const [card, setCard] = useState([]);
  useEffect(() => {
    fetch(`http://51.75.16.232:4000/api/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setCard(data[0]);
      })
      .catch((err) => {
        console.error(err);
      })
  }, [id]);
  return (
      <main className="glitter">
        <h1 className="glitter">Tessera 2024</h1>

        <aside className="card-front">
          <label className="number" htmlFor="cardNumber">
            {card.firstName} {card.lastName}
          </label>
          <label className="name" htmlFor="cardHolder">
            {card.number}
          </label>
          <label className="expiry" htmlFor="expiryMonth">
            12/24
          </label>
          <img className="cardLogoFull" src="./logo-light-full.svg" alt="Logo Ginepro intero"/>
          <img className="cardLogo" data-v-5d206127="" data-v-8fcb32d4="" style={{opacity: 1}} src="./logo-dark.svg" alt="Logo Ginepro" />
        </aside>

      </main>
  );
}

export default Glitter;
